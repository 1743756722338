import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/work-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ul className="work-links">
  <li>
    <a href="https://careers.usace.army.mil">
      <i className="fas fa-mobile-alt fa-lg"></i>
      View app
    </a>
  </li>
    </ul>
    <p>{`During my time at Applied Information Sciences I was part of the team that designed and built a progressive web application to help the U.S. Army Corps of Engineers with recruiting efforts.`}</p>
    <p>{`The application is built with React and consumes data from the USACE Jobs API.`}</p>
    <p>{`As a part of this project we built a private internal design system to begin to collect and document common components to be used in future work.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/work/usace-jobs.png",
        "alt": null
      }}></img>{`
`}<img parentName="p" {...{
        "src": "/images/work/usace-meet.png",
        "alt": null
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      